import { ContentOnexTwo } from "./styles";
import ContentImage from "@/shared/ContentImage";
import ContentTitle from "@/shared/ContentTitle";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal.js";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import useNewsDataModules from "@/hooks/useNewsDataModules";
const OnexTwo = (props) => {
  const {
    asPath,
    author = [],
    authorSignature,
    containerName,
    iconMultimedia,
    image,
    imagePreview,
    imageWeb,
    isPreview,
    payWallExclusive,
    section = [],
    subtitle,
    title,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, {
    desktop: ["square_md"],
    mobile: ["square_md"],
  });
  return (
    <>
      <NormalArticle>
        <ContentOnexTwo className="onextwo">
          <div className="centergrey">
            <ContentTitle
              type="onextwo"
              section={section}
              author={author}
              authorSignature={authorSignature}
              title={title}
              subtitle={subtitle}
              payWallExclusive={payWallExclusive}
            />
          </div>
          
          {videosOpenLoop?.enabled ?
            <VideoLoop videoLoop={videoLoop} />
            : <picture>
              {isPreview ? (
                imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
              ) : (
                <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} image={image} />
              )}
               <p className="epigraphe">{imageWeb.epigraphe}</p>
            </picture>}
          <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}/>
        </ContentOnexTwo>
      </NormalArticle>
    </>
  );
};

export default OnexTwo;
